<template>
  <div class="w-full flex flex-col gap-4 ml-3">
    <div class="w-full flex gap-3">
      <div class="w-1/2 flex flex-col gap-2">
        <div class="flex justify-between w-full items-center">
          <p class="text-sm mt-4 mx-1">Effective Date</p>
        </div>
        <div class="border border-romanSilver rounded-md">
          <datepicker placeholder="--Start Date--" input-class="date-input" style="width: 100%"
            v-model="payload.effectiveDate" />
        </div>
      </div>

      <div class="w-1/2 flex flex-col gap-2">
        <div class="flex justify-between w-full items-center mt-3"></div>
        <combobox placeholder="--Type or Select--" label="Reason for Disengagement" :items="reasonsOptions"
          v-model="payload.reason" variant="w-full" />
      </div>
    </div>

    <div class="w-full flex flex-col flex-grow p-4 gap-4 border">
      <p class="text-romanSilver text-sm">Comment</p>
      <p class="text-sm">
        You have initiated the process of disengaging
        {{ employeeDetails.fname }} {{ employeeDetails.lname }} from your
        organization. Before you proceed, please review the disengagement
        checklist to ensure that your operations continue to run smoothly after
        the disengagement and to account for any terminal benefits, debts, and
        payroll needs.
      </p>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { format } from 'date-fns'
import Combobox from "@scelloo/cloudenly-ui/src/components/combobox";

export default {
  name: "ExtendProbation",
  components: { Datepicker, Combobox },
  props: {
    employeeDetails: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      reasonsOptions: [],
      isDisabled: true,
      payload: {
        userId: this.employeeDetails.userId,
        reason: null,
        comment: "",
        effectiveDate: "",
      },
      debounceTimer: null,
    };
  },

  watch: {
  payload: {
    deep: true,
    immediate: true,
    handler(value) {

      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(async () => {

        if (!value.reason || !value.effectiveDate) {
          return;
        }

        try {
          const effectiveDateFormatted = format(new Date(value.effectiveDate), "yyyy-MM-dd");

          const basicInfoPayload = {
            extendBy: 1,
            orgId: this.$orgId,
            comments: value.comment,
            confirmUserId: this.employeeDetails.userId,
            additionalComment: value.comment,
            payrollStatus: this.employeeDetails.payrollStatus === "active",
            effectiveDate: effectiveDateFormatted,
            initiatedBy: this.$AuthUser.id,
          };

          const reason = this.reasonsOptions.find(item => item.id === value.reason);

          if (!reason) {
            basicInfoPayload.reason = value.reason;
          } else {
            basicInfoPayload.reasonId = reason.id;
          }

          this.$emit("infoPayload", basicInfoPayload);
        } catch (error) {
          console.error("Error processing reason:", error);
        }
      }, 1000);
    },
  },
},

  methods: {
    async getSuspensionReason() {
      try {
        const { data } = await this.$_getSuspensionReason();
        this.reasonsOptions = data.reasons?.map(reason => ({
          ...reason,
          value: reason.reason,
        })) || [];
      } catch (error) {
        console.error("Error fetching suspension reasons:", error);
      }
    },
  },

  async mounted() {
    await this.getSuspensionReason();
  }
}
</script>
